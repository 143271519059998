<template>
  <div class="view-user-section">
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <!-- <div id="progressBar">
    <div class="loader"></div>
      </div>-->
      <div>
        <div class="">
          <div class="row">
            <div class="col-md-4">
              <div class="heading-profile">
                <h3>User Profile</h3>
              </div>
            </div>
            <div class="col-md-4 msg">
              <b-button class="btn-danger" to="/message">Send Message from WedDestination</b-button>
            </div>
          </div>
        </div>
        <!-- Top Bar Chart -->
        <div class="d-flex ">
          <div class="panel-content heading-profile mx-3">
            <div class="widget user-profile-tab-section roboto-regular gray1">
              <div class="profile-form-section ml-5 mr-3" v-if="profile && profile.email">
                <b-row no-gutters>
                  <b-col md="10" lg="5">
                    <div class="mt-3">
                      <b-form-group>
                        <label class="font-14">
                          Full Name:
                        </label>
                        <b-form-input id="name" v-model="profile.name"></b-form-input>
                      </b-form-group>
                    </div>
                  </b-col>
                  <!-- <b-col md="2">
                    <div class="edit-section ml-2">
                        <nuxt-link to="/">Edit</nuxt-link>
                    </div>
                </b-col> -->
                </b-row>
                <b-row no-gutters>
                  <b-col md="10" lg="5">
                    <div>
                      <b-form-group>
                        <label class="font-14">
                          Registered Email:
                        </label>
                        <b-form-input
                          id="email"
                          type="email"
                          v-model="profile.email"
                          readonly
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
                <b-row no-gutters>
                  <b-col md="10" lg="5">
                    <div>
                      <b-form-group>
                        <label class="font-14">
                          Registered Mobile No:
                        </label>
                        <b-form-input id="phone" type="" v-model="profile.phone"></b-form-input>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>

                <b-row no-gutters>
                  <b-col md="3" lg="3">
                    <div>
                      <b-form-group>
                        <label class="font-14">
                          Country
                        </label>
                        <b-form-select
                          v-model="profile.country"
                          :options="allCountries"
                          @input="setState(profile.country)"
                        ></b-form-select>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3" lg="3">
                    <div class="mr-3">
                      <b-form-group>
                        <label class="font-14">
                          State
                        </label>
                        <b-form-select
                          v-model="profile.state"
                          :options="states"
                          @input="setCity(profile.state)"
                        ></b-form-select>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3" lg="3">
                    <div class="mr-3">
                      <b-form-group>
                        <label class="font-14">
                          City
                        </label>
                        <!-- <b-form-select v-model="profile.city" :options="cities"></b-form-select> -->
                        <b-input v-model="profile.city"></b-input>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
                <b-row no-gutters>
                  <b-col md="10" lg="5">
                    <div>
                      <b-form-group>
                        <label class="font-14">Date of Registration:</label>

                        <b-form-input disabled v-model="profile.date"> </b-form-input>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
                <b-row no-gutters>
                  <b-col md="5" lg="5">
                    <div class="save-btn mb-5">
                      <b-button class="btn btn-success shadow-none border-0" @click="saveUser()"
                        >Save</b-button
                      >
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <!-- <sideMenu :navigations="navigation" /> -->

          <div>
            <div class="inside-menu mx-3">
              <div class="side-header light-skin opened-menu">
                <div>
                  <div class="side-menus">
                    <nav class="pt-0">
                      <div class="icon-active-color border-bottom border-Ttop">
                        <router-link :to="'/userInvoice/' + id" class=""
                          ><div class="link-text p-3 text-uppercase">
                            invoice Info
                          </div></router-link
                        >
                      </div>
                      <div class="icon-active-color border-bottom border-Ttop">
                        <router-link
                          :to="'/viewUserDetail/' + id"
                          class="router-link-exact-active router-link-active"
                          ><div class="link-text p-3 text-uppercase">
                            user profile
                          </div></router-link
                        >
                      </div>
                      <div class="icon-active-color border-bottom border-Ttop">
                        <router-link :to="'/userPurchaseHistory/' + id" class="">
                          <div class="link-text p-3 text-uppercase">
                            Transaction
                          </div>
                        </router-link>
                      </div>
                      <div class="icon-active-color border-bottom border-Ttop">
                        <router-link :to="'/userDealsOffers/' + id" class="">
                          <div class="link-text p-3 text-uppercase">
                            Deals &amp; Offers
                          </div>
                        </router-link>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerCustom from "@/components/Header.vue";
import apiservice from "@/service/apiservice";
import moment from "moment";
import allCountries from "@/js/country";
import allStates from "@/js/state";
import allCities from "@/js/city";
// import sideMenu from "@/components/subheader.vue";

export default {
  components: {
    headerCustom
    // sideMenu
  },
  data() {
    return {
      navigation: {},
      id: this.$route.params.id,
      format: "MMMM DD YYYY",
      moment: moment,
      allCountries: [],
      allStates: [],
      allCities: [],
      states: [],
      cities: [],
      profile: {
        name: "",
        email: "",
        phone: "",
        designation: "",
        userCreatedAt: "",
        city: ""
        // menu: [
        //   {
        //     name: "Info",
        //     path: "/userInvoice/"
        //   },
        //   {
        //     name: "details",
        //     path: `/viewUserDetail/`
        //   },
        //   {
        //     name: "Transaction",
        //     path: "/userPurchaseHistory"
        //   }
        // ]
      }
    };
  },
  created() {
    this.allCountries = allCountries;
    this.allStates = allStates;
    this.allCities = allCities;
    this.navigation._id = this.$route.params.id;
    this.navigation.menu = this.menu;
    this.getUserInfo(this.id);
  },
  methods: {
    getUserInfo() {
      apiservice.getUserProfile({ id: this.id }, data => {
        if (data.status === 200) {
          this.profile = data.data;
          // this.setSelectedCityCountryState();
          this.profile.date = moment(this.profile.userCreatedAt).format("DD/MM/YYYY");
        } else {
      
        }
      });
    },
    setState(value) {
      this.states = [];
      this.allStates.forEach(x => {
        if (x.country_id == value) {
          this.states.push({
            text: x.name,
            value: x.id
          });
        }
      });
    },
    setCity(value) {
      this.cities = [];
      this.allCities.forEach(x => {
        if (x.state_id == value) {
          this.cities.push({
            text: x.name,
            value: x.id
          });
        }
      });
    },
    saveUser() {
      // this.getSelectedCityCountryState();
      apiservice.updateUserProfile({ user: this.profile }, data => {
        if (data.status === 200) {
          this.$toaster.success("Details updated.");
          this.getUserInfo();
        } else {
          this.$toaster.error("Not saved.");
        }
      });
    },
    getSelectedCityCountryState() {
      const country = this.allCountries.find(x => this.profile.country == x.value);
      if (country) {
        this.profile.country = country.text;
      }
      const state = this.states.find(x => this.profile.state == x.value);
      if (state) {
        this.profile.state = state.text;
      }
      const city = this.cities.find(x => this.profile.city == x.value);
      if (city) {
        this.profile.city = city.text;
      }
    },
    setSelectedCityCountryState() {
      const country = this.allCountries.find(x => this.profile.country == x.text);
      if (country) {
        this.setState(country.value);
        this.profile.country = country.value;
      }
      const state = this.allStates.find(x => this.profile.state == x.name);
      if (state) {
        this.setCity(state.id);
        this.profile.state = state.id;
      }
      const city = this.allCities.find(x => this.profile.city == x.name);
      if (city) {
        this.profile.city = city.id;
      }
    }
  }
};
</script>

<style lang="scss">
.view-user-section {
  .inside-menu {
    .side-header {
      height: auto;
      width: 250px;
      margin-top: 25%;
    }
    .side-header.light-skin {
      background: #ffffff none repeat scroll 0 0;
    }
  }
  .msg {
    margin-left: 77px;
  }
}
</style>
